import React from 'react';
import '../Style/BottomNav.scss';

/* import Profile from '../Assets/navbar-icons/profile.svg';
import Contacts from '../Assets/navbar-icons/contacts.svg';
import Star from '../Assets/navbar-icons/star.svg';
import Stars from '../Assets/navbar-icons/stars.svg'; */

import HomeIcon from '@material-ui/icons/Home';
import ContactsIcon from '@material-ui/icons/Contacts';
import PersonIcon from '@material-ui/icons/Person';
import StarsIcon from '@material-ui/icons/Stars';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { Link, useLocation } from 'react-router-dom';
import { VIEWS } from '../utils';
import jwt from 'jsonwebtoken';

export default function BottomNavigation() {
    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const location = useLocation();
    const path = location?.pathname;

    return (
        <div className="bottom-navbar">
            <Link to={VIEWS.HOME}>
                <div className="icon-wrap" data-active={path === VIEWS.HOME}>
                    <div className="icon-box">
                        <HomeIcon className="icon" />
                    </div>
                    <p className="text">Home</p>
                </div>
            </Link>
            {/* <Link to={VIEWS.INVITE}>
                <div className="icon-wrap" data-active={path === VIEWS.INVITE}>
                    <div className="icon-box">
                        <ControlPointIcon className="icon" />
                    </div>
                    <p className="text">Invite</p>
                </div>
            </Link>
            <Link to={VIEWS.MYCONTACT}>
                <div className="icon-wrap" data-active={path === VIEWS.MYCONTACT}>
                    <div className="icon-box">
                        <ContactsIcon className="icon" />
                    </div>
                    <p className="text">Contacts</p>
                </div>
            </Link> */}
            {/* <Link to={`/${uid}${VIEWS.MYREWARDS}`}>
                <div className="icon-wrap" data-active={path === `/${uid}${VIEWS.MYREWARDS}`}>
                    <div className="icon-box">
                        <StarsIcon className="icon" />
                    </div>
                    <p className="text">Rewards</p>
                </div>
            </Link> */}
            <Link to={VIEWS.PROFILE}>
                <div className="icon-wrap" data-active={path === VIEWS.PROFILE}>
                    <div className="icon-box">
                        <PersonIcon className="icon" />
                    </div>
                    <p className="text">Profile</p>
                </div>
            </Link>
        </div>
    );
}
