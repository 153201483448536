import React, { Component } from 'react';

function Button({
    innerRef,
    onClick,
    name,
    disabled,
    className,
    ...props
}: {
    innerRef?: any;
    onClick?: any;
    name?: any;
    disabled?: any;
    className?: string;
    [key: string]: any;
}) {
    return (
        <button
            ref={innerRef}
            onClick={onClick}
            className={`btn-green ${className}`}
            disabled={disabled}
            {...props}>
            {name}
        </button>
    );
}

export default Button;
