import React, { Component } from 'react';

function BoxbtnTransparent(props: { onClick: any; icon: any; name: any }) {
    return (
        <React.Fragment>
            <button onClick={props.onClick} className="boxbtn-transparent">
                <img src={props.icon} className="iconPadding"></img>
                {props.name}
            </button>
        </React.Fragment>
    );
}

export default BoxbtnTransparent;
