import React from 'react';
import CloseIconShare from '../Assets/closeIconShare.svg';
import DoneVector from '../Assets/doneFilled.svg';
import ShareIcon from '../Assets/shareGreen.svg';
import AddIcon from '../Assets/plusIcon.svg';
import LinkedInIcon from '../Assets/share-icons/linkedin.svg';
import FaceBookIcon from '../Assets/share-icons/facebook.svg';
import InstagramIcon from '../Assets/share-icons/instagram.svg';
import LinkIcon from '../Assets/share-icons/link.svg';
import '../Style/ShareComponent.scss';
import axios from 'axios';
import Alert from './Alert';
import { CircularProgress } from '@material-ui/core';

export default function ShareComponent(props: {
    rewardId: string;
    posts: any;
    selectedPost: any;
    setSelectedPost: any;
    closeShareOn: any;
    shareOnLinkedIn: any;
    shareOnFacebook: any;
    linkValue: any;
    clickCopy: any;
    copiedTooltip?: any;
    addToLinkedIn?: any;
}) {
    const [alertOpen, setAlertOpen] = React.useState({ open: false, title: '', content: '' });
    const [updatingItem, setUpdatingItem] = React.useState(null);
    const [selectedPostData, setSelectedPostData] = React.useState('');
    const [isLinkedinMenuOpen, setIsLinkedInMenuOpen] = React.useState(false);

    function closeShareOn() {
        props.closeShareOn(false);
    }

    React.useEffect(() => {
        if (props?.selectedPost !== null) {
            setSelectedPostData(props?.posts[props?.selectedPost]?.post);
        }
    }, [props?.posts, props?.selectedPost]);

    const onSelectPost = (data, index) => {
        setUpdatingItem(index);
        axios
            .patch(`${process.env.REACT_APP_REWARD_BACKEND}reward/updatePost`, {
                id: props?.rewardId,
                selectedId: index
            })
            .then(() => {
                props.setSelectedPost(index);
            })
            .catch(() => {
                setAlertOpen({
                    open: true,
                    title: 'Error',
                    content: "Couldn't update the selected post. Try again"
                });
            })
            .finally(() => {
                setUpdatingItem(null);
            });
    };

    return (
        <div className="share-popup">
            <div className="top-wrap">
                <h1 className="title">Share Reward</h1>
                <button className="close-button" onClick={closeShareOn}>
                    <img src={CloseIconShare} alt="Close Icon" />
                </button>
            </div>

            {props?.posts.length > 1 && (
                <>
                    <p className="note">Select Post Style</p>

                    {selectedPostData ? (
                        <img src={selectedPostData} className="selected-post" alt="Selected Post" />
                    ) : (
                        'No post is selected'
                    )}

                    <div className="posts-wrap">
                        <div className="posts">
                            {props?.posts?.length === 0
                                ? 'No posts are available to share'
                                : props?.posts?.map((_, i) => {
                                      return (
                                          <div
                                              onClick={() => onSelectPost(_, i)}
                                              key={`post-image-${i}`}
                                              data-selected={i === props?.selectedPost}
                                              className="post-wrap">
                                              {updatingItem === i && (
                                                  <CircularProgress className="progress" />
                                              )}
                                              <img
                                                  src={DoneVector}
                                                  alt="Done Icon"
                                                  className="done-icon"
                                              />
                                              <img src={_?.post} alt="Post" className="post" />
                                          </div>
                                      );
                                  })}
                        </div>
                    </div>
                </>
            )}

            <div className="social-media-icons">
                <div className="social-wrap">
                    <div data-open={isLinkedinMenuOpen} className="social-menu">
                        <div onClick={props.shareOnLinkedIn} className="item">
                            <img src={ShareIcon} alt="Share Icon" className="item-icon" />
                            <span>Share</span>
                        </div>
                        <div
                            onClick={() => window.open(props.addToLinkedIn, '_blank')}
                            className="item">
                            <img src={AddIcon} alt="Add Icon" className="item-icon" />
                            <span>Add to Profile</span>
                        </div>
                    </div>
                    <img
                        onClick={() => setIsLinkedInMenuOpen(!isLinkedinMenuOpen)}
                        src={LinkedInIcon}
                        alt="Linkedin"
                        className="icon"
                    />
                    <span className="icon-name">LinkedIn</span>
                </div>
                <div onClick={props.shareOnFacebook} className="social-wrap">
                    <img src={FaceBookIcon} alt="Facebook" className="icon" />
                    <span className="icon-name">Facebook</span>
                </div>
                {/* <div className="social-wrap">
                    <img src={InstagramIcon} alt="Instagram" className="icon" />
                    <span className="icon-name">Instagram</span>
                </div> */}
                <div onClick={props.clickCopy} className="social-wrap">
                    <img src={LinkIcon} alt="Copy Link" className="icon" />
                    <span className="icon-name">Copy Link</span>
                    {props.copiedTooltip ? (
                        <div className="tool-tip">
                            Copied to
                            <br />
                            Clipboard
                        </div>
                    ) : null}
                </div>
            </div>

            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </div>
    );
}
