export const base64ToFile = (base64String: string, fileName: string) => {
    let arr: any = base64String.split(','),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: 'image' });
};

export const ipfsToHTTPS = (url: string) => {
    if (!url.startsWith('ipfs://')) throw new Error('Not an IPFS url');
    const cid = url.substring(7);
    return `https://ipfs.io/ipfs/${cid}`;
};

export const minifyAddress = (address: string) => {
    const start = address.substring(0, 5);
    const end = address.substring(address.length - 4);
    return `${start}...${end}`;
};

export const dateDiffInDays = (a: any, b: any) => {
    const diffTime = Math.abs(a - b);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};
export const dateDiffInMinutes = (a: any, b: any) => {
    const diffTime = Math.abs(a - b);
    const diffDays = Math.ceil(diffTime / (1000 * 60));
    return diffDays;
};
