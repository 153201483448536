import React, { useEffect, useState } from 'react';
import '../Style/App.scss';
import '../Style/Responsive.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from '../components/Spinner';
import Elements from '../elements';
import TopNavigationHeader from '../components/TopNavigationHeader';

function MyRewardCredentialPreview() {
    const history = useNavigate();
    const location: any = useLocation();
    const [reward, setReward] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {
        setIsDataLoading(true);
        if (location.state?.reward && location.state?.reward != 'undefined') {
            setReward(location.state?.reward);
            setIsDataLoading(false);
        }
        //console.log(rewards)
    }, [location]);

    function handleBack() {
        history(-1);
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader caption="Reward" handleBack={handleBack} />
                <Elements
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    loadingData={isDataLoading}
                    reward={reward ?? {}}
                    allowHide={false}
                    addToTrophy={false}
                    hideShare
                />
            </div>
            {(isLoading || isDataLoading) && <Spinner />}
        </React.Fragment>
    );
}

export default MyRewardCredentialPreview;
