import React, { Component } from 'react';
import logo from '../Assets/logo.svg';

function Logo() {
    return (
        <div className="logo">
            <img src={logo}></img>
        </div>
    );
}

export default Logo;
