import keccak256 from 'keccak256';
import { fabric } from 'fabric';
import WebFont from 'webfontloader';

export enum VIEWS {
    ONBORDING = '/',
    ISSUE = '/issue',
    ACCEPT = '/accept',
    VERIFIER = '/verify',
    SIGNEDVC = '/issuedReward',
    SIGNEDVP = '/acceptedReward',
    IDENTITY = '/identity',
    HOME = '/home',
    MORE = '/verificationDetails',
    SIGNIN = '/signin',
    SIGNUP = '/signup',
    LINKEDIN_CALLBACK = '/linkedinCallback',
    DIDSIOP_CALLBACK = '/siopCallback',
    PROFILE = '/profile',
    MYREWARDS = '/rewards',
    MYREWARDCREDENTIALS = '/rewardCredentials',
    MYREWARDPRESENTATIONPREVIEW = '/rewardPresentationPreview',
    RECEIVEDREQUESTS = '/receivedRequests',
    RECEIVEDREWARDS = '/receivedRewards',
    RECEIVEDREWARDPREVIEW = '/receivedRewardPreview',
    MYCONTACT = '/myContacts',
    CONTACTPROFILE = '/contactProfile',
    GLOBALCONTACTS = '/contacts',
    GLOBALCONTACTPROFILE = '/userProfile',
    INVITE = '/invite',
    TROPHYCASE = '/trophyCase',
    TROPHYCASEPUBLIC = '/trophyCase/public',
    TROPHYVIEW = '/trophyView',
    TROPHYVIEWPUBLIC = '/trophyView/public',
    MYREWARDPREVIEW = '/myRewardPreview',
    MYREWARDPCREDENTIALREVIEW = '/myRewardCredentialPreview',
    ISSUEDREWARDPREVIEW = '/issuedRewardPreview',
    GENREWARDPREVIEW = '/generatedRewardPreview',
    NOTIFICATIONS = '/notifications',
    BADGES = '/quickBadges',
    REQUESTREWARD = '/requestReward',
    SEEMORE = '/seeMore',
    VERIFYDOMAIN = '/verifyDomain',
    VERIFYREWARD = '/VerifyReward',
    REWARDCERTIFICATE = '/rewardCertificate',
    NEWREWARD = '/newReward'
}

export function shareOnLinkedIn(did: string, id: string) {
    const uri = `/${did}/${id}`;
    const url = `${process.env.REACT_APP_REWARD_VIEW}${uri}`;
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, '_blank');
}

export function shareOnFacebookHelper(did: string, id: string) {
    const uri = `/${did}/${id}`;
    const url = `${process.env.REACT_APP_REWARD_VIEW}${uri}`;
    return url;
}

export const rewardTypes = {
    receivedReward: 'receivedReward',
    acceptedReward: 'acceptedReward'
};

export function isAddress(address: string) {
    if (/^[0-9a-z]{48}$/i.test(address)) {
        // check if it has the basic requirements of an address
        return true;
    } else {
        return false;
    }
}

export function isChecksumAddress(address: string) {
    // Check each case
    address = address.replace('0x', '');
    const addressHash: string = keccak256(address.toLowerCase()).toString('hex');
    for (var i = 0; i < 48; i++) {
        // the nth letter should be uppercase if the nth digit of casemap is 1
        if (
            (parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) ||
            (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])
        ) {
            return false;
        }
    }
    return true;
}

export function isDID(did: string) {
    return (
        did &&
        did.split(':')[0] === 'did' &&
        did.split(':')[1] === 'key' &&
        isAddress(did.split(':')[2])
    );
}

export async function canvasToImage(json: any) {
    return new Promise(async (resolve: any) => {
        /* load fonts */
        await loadFonts(json);

        const canvas: any = new fabric.Canvas('');

        canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), (object: any) => {
            // objects
        });

        const interval: any = setInterval(() => {
            if (canvas?._objects?.length !== json?.objects?.length) return;

            clearInterval(interval);

            var group: any = new fabric.Group(canvas._objects, {
                left: 150,
                top: 100
            });

            const img: any = group.toDataURL({
                format: 'png',
                quality: 0.8
            });

            return resolve(img);
        }, 100);
    });
}

const loadFonts = async (json: any) => {
    return new Promise((resolve) => {
        const families: any = [];
        const ignore = ['Arial'];

        if (Array.isArray(json?.objects)) {
            for (const obj of json?.objects) {
                if (obj.fontFamily && !ignore.includes(obj.fontFamily))
                    families.push(obj.fontFamily);
            }
        }

        if (families.length === 0) return resolve(true);

        WebFont.load({
            google: {
                families: families
            },
            loading: function () {
                console.log('Fonts are being loaded');
            },
            active: function () {
                console.log('Fonts have been rendered');

                return resolve(true);
            }
        });
    });
};

export const isJson = (str: any) => {
    try {
        JSON.parse(str);
    } catch (error) {
        return false;
    }

    return true;
};

export const injectDataToCanvas = (json: any = {}, { text = {}, images = {} }: any) => {
    if (Array.isArray(json?.objects)) {
        for (const object of json?.objects) {
            if (object.type === 'textbox' || object.type === 'text') {
                if (object.text) {
                    const keywords = object.text.match(/\{{[^)]*\}}/g) || [];

                    for (const keyword of keywords) {
                        // let tag = keyword.match(/(?<=\{\{).*?(?=\}\})/g)[0];
                        let tag = keyword.match(/[^{\{]+(?=}\})/g)[0];
                        if (tag) tag = tag.replace(/\s/g, '');

                        const detail = text[tag] || '';
                        object.text = object.text.replaceAll(keyword, detail);
                    }
                }
            }

            if (object.type === 'image' || object.type === 'group') {
                if (object.type === 'image') {
                    object.src = images[object.name] === 'show' ? object.src : images[object.name];
                }
            }
        }
    }

    return json;
};

export function base64ToJson(base64String: any) {
    if (!base64String) return '';

    const json = Buffer.from(base64String, 'base64').toString();
    return JSON.parse(json);
}

export function parseJwt(token: any) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}
