import React from 'react';

import ReceivedRewardPreviewUser from '../../components/RewardPreviewUser';
import { useAppSelector } from '../../app/hooks';
import { _image } from '../../redux/identitySlice';
import propic from '../../Assets/defaultUser.jpg';

function Badge(props: any) {
    const profileImage = useAppSelector(_image);
    const [Image, setImage] = React.useState('');

    function getDate(date: string) {
        try {
            const date2 = new Date(date);
            const month: number = date2.getMonth() + 1;
            const monthString = month.toString().length > 1 ? month.toString() : '0' + month;
            const day = date2.getDate();
            const dayString = day.toString().length > 1 ? day.toString() : '0' + day;
            return date2.getFullYear() + '-' + monthString + '-' + dayString;
        } catch (e) {
            return date;
        }
    }

    React.useEffect(() => {
        if (props?.visualPresentation) {
            setImage(props.visualPresentation);
        }
    }, [props]);

    return (
        <React.Fragment>
            <div className="no-top-border-radius ReceivedRewardPreview-wrap">
                <div className="coin-section">
                    <img className="coin-img badge-preview" src={Image}></img>
                    <p className="title-txt-blue">{props?.rewardTitle}</p>
                    <p className="normal-txt-black">{props?.remarks} </p>
                </div>

                <div className="extra-mt"></div>

                <ReceivedRewardPreviewUser
                    heading="Issued To"
                    profileImage={props?.holderImage || profileImage || propic}
                    userName={props?.holderName}
                    url={props?.holderProfileUrl}
                />

                <div className="extra-mt2"></div>

                <ReceivedRewardPreviewUser
                    heading="Issued by"
                    profileImage={props?.issuerLogo}
                    userName={props?.issuerName}
                    url={props?.issuerUrl}
                />

                <div className="extra-mt2"></div>

                <ReceivedRewardPreviewUser
                    heading="Issued on"
                    userName={getDate(props?.issuanceDate)}
                />
            </div>
        </React.Fragment>
    );
}

export default Badge;
