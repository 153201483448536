// import { File } from 'nft.storage';
import { NFTStorage, File } from 'nft.storage/dist/bundle.esm.min.js';
import { base64ToFile } from './helpers';
const client = new NFTStorage({
    token: `${process.env.REACT_APP_NFT_STORAGE}`
});

export const nftStorage = async (name: string, description: string, json: any) => {
    try {
        const image = base64ToFile(
            json.verifiableCredential[0].credentialSubject.visualPresentation,
            'visualRepresentation.png'
        );
        const jsonString = JSON.stringify(json);
        const jsonBlob = new Blob([jsonString], { type: 'application/json' });

        const jsonFile = new File([jsonBlob], 'myObject.json', {
            type: 'application/json'
        });
        const metadata = await client.store({
            name,
            description,
            image,
            jsonFile
        });

        return metadata.url;
    } catch (error) {
        console.error(error);
    }
};
