import axios from 'axios';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FacebookShareButton } from 'react-share';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import arrowAccept from '../Assets/arrowAccept.svg';
import closeIconGreen from '../Assets/closeIconGreen.svg';
import nftLogo from '../Assets/NFT-icons/NFT.svg';
import plusCircle from '../Assets/plusCircle.svg';
import shareIconSolid from '../Assets/shareIconSolid.svg';
import singleCloseIcon from '../Assets/singleCloseIcon.svg';
import Alert from '../components/Alert';
import Boxbtn from '../components/Boxbtn';
import BoxbtnTransparent from '../components/BoxbtnTransparent';
import PopupQuestion from '../components/PopupQuestion';
import ReceivedRewardPreviewUser from '../components/RewardPreviewUser';
import ShareComponent from '../components/ShareComponent';
import { _receivingPrivateKey } from '../redux/identitySlice';
import '../Style/Elements.scss';
import { shareOnFacebookHelper, shareOnLinkedIn, VIEWS } from '../utils';
import createVP from '../utils/create-vp';
import credentials from '../utils/credentials';
import Element from './element';
import samples from './samples.json';
import Web3Modal from 'web3modal';
import { connectWallet } from '../utils/nftMarket/actions';
import useNFTMarket from '../utils/nftMarket';

interface Props {
    loadingData?: boolean;
    acceptReward?: boolean;
    hideShare?: boolean;
    addToTrophy?: boolean;
    removeFromTrophy?: boolean;
    allowHide?: boolean;
    hideDownloadButtons?: boolean;
    isLoading?: boolean;
    setIsLoading?: any;
    reward: any;
    onAlertClose?: string;
}

export default function Elements({
    loadingData = true,
    acceptReward = false,
    hideShare = false,
    addToTrophy = true,
    removeFromTrophy = false,
    allowHide = false,
    hideDownloadButtons = false,
    onAlertClose = VIEWS.MYREWARDS,
    setIsLoading,
    isLoading = false,
    reward
}: Props) {
    const claims = reward;
    const { elements }: any = samples;
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const location = useLocation();
    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(location.search).get('id');
    const holderPrivateKey = useAppSelector(_receivingPrivateKey);

    const fbShareRef = React.useRef<HTMLButtonElement>(null);

    const [tab, setTab] = React.useState(0);
    const [alertOpen, setAlertOpen] = React.useState({ open: false, title: '', content: '' });
    const [shareOn, setShareOn] = React.useState(false);
    const [copiedTooltip, setCopiedTooltip] = React.useState(false);
    const [popUp, setPopUp] = React.useState(false);
    const [type, setType] = React.useState('');
    const [hide, setHide]: any = React.useState({});
    const [posts, setPosts]: any = React.useState([]);
    const [selectedPost, setSelectedPost]: any = React.useState(null);

    const addToLinkedInUrl = React.useMemo(() => {
        let link = '';

        if (reward) {
            try {
                const item: any = reward;
                const element = item?.elements?.[tab];

                if (!element) return;
                const data = credentials.get(element.type, element.vc);
                let title = data.title;

                const date = moment(data?.issuanceDate);
                const certUrl: any = shareOnFacebookHelper(uid, id || '');
                //console.log(date.getMonth());
                const url = new URL('https://www.linkedin.com/profile/add');
                url.searchParams.append('startTask', 'CERTIFICATION_NAME');
                url.searchParams.append('name', title);
                url.searchParams.append('organizationName', data?.issuerName);
                url.searchParams.append('issueYear', date.format('YYYY').toString());
                url.searchParams.append('issueMonth', (date.month() + 1).toString());
                url.searchParams.append('certUrl', certUrl);
                if (element?.issuerLinkedin) {
                    url.searchParams.append('organizationId', element?.issuerLinkedin);
                }

                if (url) link = url.href;
            } catch (error) {
                //console.log(error);
            }
        }

        return link;
    }, [tab, reward]);

    React.useEffect(() => {
        if (reward) {
            const item: any = reward;
            const elements = item?.elements;
            const element = elements?.[0];

            const hideData: any = {};

            if (Array.isArray(elements)) {
                for (const el of elements) {
                    hideData[el?.type] = !!el?.hide;
                }
            }

            setHide(hideData);

            if (!element) return;

            onTab(0, element?.type);
        }

        // const checkMintedStatus = async () => {
        //     const elements = reward?.elements;
        //     if (elements) {
        //         for (const [index, value] of elements.entries()) {
        //             if (!value?.mintingConfirmed) {
        //                 try {
        //                     const minted = await nftExists(value?._id);

        //                     if (minted) {
        //                         const res = await axios.put(
        //                             `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/confirm/${value?._id}`
        //                         );
        //                         if (!value?.mintedTimestamp) {
        //                             const res = await axios.put(
        //                                 `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/updatetime/${value?._id}`
        //                             );
        //                         }
        //                         setMintedIndexes([...mintedIndexes, index]);
        //                     } else {
        //                         const res = await axios.delete(
        //                             `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/error/${value?._id}`
        //                         );
        //                     }
        //                 } catch (error) {
        //                     console.error(error);
        //                 }
        //             }
        //         }
        //     }
        // };

        // checkMintedStatus();
    }, [reward]);

    React.useEffect(() => {
        const web3Modal = new Web3Modal();
        if (web3Modal.cachedProvider) {
            connectWallet(dispatch);
        }
        if (window.ethereum?.isMetaMask) {
            window.ethereum.on('accountsChanged', () => {
                connectWallet(dispatch);
            });
        }
    }, []);

    React.useEffect(() => {
        if (id) {
            getRewardPosts(id);
        }
    }, [id]);

    function addToTrphyCase() {
        setIsLoading(true);
        addToCase(uid, id || '')
            .then((res) => {
                setIsLoading(false);
                setAlertOpen({
                    open: true,
                    title: 'Success',
                    content: 'Reward added to Favorite Rewards.'
                });
                //console.log(res);
            })
            .catch((e) => {
                //console.log(e);
            });
    }

    const addToCase = async (did: string, id: string) => {
        const rewards = await axios.put(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/addToTrophyCase`,
            {
                did,
                id
            }
        );
        return rewards;
    };

    function goToMyRewards() {
        setAlertOpen({ ...alertOpen, open: false });
        if (onAlertClose === VIEWS.MYREWARDS) {
            history(`/${uid}${onAlertClose}`);
        } else {
            history(onAlertClose);
        }
    }

    function shareOnFacebook() {
        return shareOnFacebookHelper(uid, id || '');
    }

    function shareOnLinkedin() {
        return shareOnLinkedIn(uid, id || '');
    }

    function shareOnDisplay() {
        setShareOn(true);
    }

    function copyURL() {
        setCopiedTooltip(true);

        navigator.clipboard.writeText(shareOnFacebookHelper(uid, id || ''));
        setTimeout(() => {
            setCopiedTooltip(false);
        }, 2000);
    }
    const removeFromCase = async (did: string, id: string) => {
        const rewards = await axios.put(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/removeFromTrophyCase`,
            {
                did,
                id
            }
        );
        return rewards;
    };

    function removeFromTrophyCase() {
        setIsLoading(true);
        //console.log('remove from TROPHY CASE', uid, id);
        removeFromCase(uid, id || '')
            .then((res) => {
                //console.log(res)
                setIsLoading(false);
                setAlertOpen({
                    open: true,
                    title: 'Success',
                    content: 'Reward removed from Favorite Rewards.'
                });
            })
            .catch((e) => {
                //console.log(e)
            });
    }

    const cancelReward = async (did: string, id: string) => {
        const rewards = await axios.delete(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/declineRewardCredential`,
            {
                data: {
                    did,
                    id
                }
            }
        );
        return rewards;
    };

    function declineReward() {
        setIsLoading(true);
        cancelReward(uid, id || '')
            .then((res) => {
                //console.log('declined.', res.data);
                setIsLoading(false);
                history(VIEWS.RECEIVEDREWARDS);
            })
            .catch((e) => {
                setIsLoading(false);
                //console.log(e)
            });
    }

    const acceptRewards = async (
        did: string,
        id: string,
        vp: any,
        rewardId: string,
        vcsData: any
    ) => {
        const rewardsRes = await axios.put(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/acceptRewardCredential`,
            {
                did,
                id,
                vp,
                rewardId,
                vcsData
            }
        );
        return rewardsRes;
    };

    const getRewardPosts = async (id: string) => {
        const response = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/getPosts`, {
            params: {
                id
            }
        });

        if (response?.data?.data) {
            setPosts(response?.data?.data?.posts);
            if (response?.data?.selectedPost !== null) setSelectedPost(response.data.selectedPost);
        }

        return response;
    };

    async function acceptRewardFunc() {
        setIsLoading(true);
        // const vp = present(holderPrivateKey, [reward] as any, [{}]) as any;
        const vcs = reward.elements.map((item: any) => item.vc);
        const vcsData = reward.elements.map((item: any) => {
            return {
                id: item?._id,
                hide: false,
                issuerLinkedin: item?.issuerLinkedin
            };
        });

        if (vcs.length === 0) {
            return setAlertOpen({
                title: 'Error',
                content: 'At least one reward is required to accept the reward',
                open: true
            });
        }
        const vp: any = await createVP({ did: uid, vcs: vcs, private_key: holderPrivateKey });

        if (vp?.error) {
            return setAlertOpen({
                title: 'Error',
                content: vp?.error,
                open: true
            });
        }

        acceptRewards(uid, id || '', vp, reward?.reward, vcsData)
            .then((res) => {
                setIsLoading(false);
                //console.log('accepted', res.data);
                setAlertOpen({
                    title: 'Success',
                    content:
                        'You have successfully signed and accepted the reward, visit My rewards section to view your rewards',
                    open: true
                });
            })
            .catch((e) => {
                /* setAlertOpen({
                    title: 'Error',
                    content: 'Failed to accept the reward, please try again later',
                    open: true
                }); */
                setIsLoading(false);
                //console.log(e)
            });
    }

    const onTab = (i: number, type: string) => {
        setTab(i);
        setType(type);
    };

    const onElementHide = (state: boolean, type: string) => {
        setHide({ ...hide, [type]: state });
    };

    const onSelectPost = (index) => {
        getRewardPosts(id);
        setSelectedPost(index);
    };

    if (loadingData)
        return (
            <div className="wrap-full">
                <p className="emptyRewardsTitle">Loading ...</p>
            </div>
        );

    if (!loadingData && !isLoading && !reward?.reward)
        return (
            <div className="wrap-full">
                <p className="emptyRewardsTitle">Reward not found</p>
            </div>
        );

    return (
        <React.Fragment>
            {shareOn ? <div className="share-component-overLay"></div> : null}

            <div className="wrap-full">
                <div className="tabSection">
                    {claims?.elements?.map((item: any, i: number) => {
                        return (
                            <button
                                key={i}
                                className={tab === i ? 'tabSectionBtnActive' : 'tabSectionBtn'}
                                onClick={() => onTab(i, item?.type)}>
                                {item?.mintingConfirmed ? <img src={nftLogo} alt="" /> : null}
                                {item?.type in elements ? elements[item?.type] : 'OTHER'}
                            </button>
                        );
                    })}
                </div>

                {claims?.elements?.map((item: any, i: number) => {
                    const element = credentials.get(item.type, item.vc);

                    if (i === tab && item?.type) {
                        return (
                            <Element
                                key={i}
                                index={i}
                                type={item?.type}
                                vc={item.vc}
                                element={element}
                                minted={item?.mintingConfirmed}
                                mintedTimestamp={item.mintedTimestamp}
                                allowHide={allowHide}
                                viewed={!!item?.viewed}
                                hide={!!hide[item?.type]}
                                setHide={(state: boolean) => onElementHide(state, type)}
                                hideDownloadButtons={hideDownloadButtons}
                            />
                        );
                    }

                    return '';
                })}

                {/* {tab === claims?.elements?.length && type === 'MODEL' && (
                    <div className="no-top-border-radius ReceivedRewardPreview-wrap mg-bottom">
                        <Model
                            model={'/models/Coin.glb'}
                            // position={[0, -6, 0]}
                            rotation={[0, Math.PI / 2, 0]}
                        />
                    </div>
                )}
                {tab === claims?.elements?.length + 1 && type === 'MODEL' && (
                    <div className="no-top-border-radius ReceivedRewardPreview-wrap mg-bottom">
                        <Model
                            model={'/models/Trophy Progress.glb'}
                            // position={[0, -15, 0]}
                            rotation={[0, Math.PI / 2, 0]}
                        />
                    </div>
                )} */}

                {type !== 'BADGE' && (
                    <div className="ReceivedRewardPreview-wrap mg-bottom">
                        <ReceivedRewardPreviewUser
                            heading="Issued by"
                            profileImage={claims?.issuerImage}
                            userName={claims?.issuerName}
                            url={claims?.issuerUrl}
                        />
                    </div>
                )}

                {!hideShare && (
                    <>
                        <div className="boxbtn-section element-btx-section">
                            {/*  {addToTrophy && (
                                <Boxbtn
                                    icon={plusCircle}
                                    name="Add to Favorite Rewards"
                                    onClick={addToTrphyCase}
                                    disabled={reward ? reward['on_trophy'] : true}
                                />
                            )}
                            {removeFromTrophy && (
                                <BoxbtnTransparent
                                    icon={singleCloseIcon}
                                    name="Remove from Favorite Rewards"
                                    onClick={removeFromTrophyCase}
                                />
                            )} */}
                            {/* @ts-ignore */}
                            <FacebookShareButton
                                hidden
                                children={undefined}
                                url={shareOnFacebook()}
                                ref={fbShareRef}
                            />
                            <Boxbtn
                                icon={shareIconSolid}
                                name="Share on"
                                onClick={shareOnDisplay}
                            />
                        </div>
                        {shareOn && (
                            <ShareComponent
                                rewardId={id}
                                posts={posts}
                                selectedPost={selectedPost}
                                setSelectedPost={onSelectPost}
                                addToLinkedIn={addToLinkedInUrl}
                                shareOnLinkedIn={shareOnLinkedin}
                                shareOnFacebook={() => {
                                    fbShareRef!.current?.click();
                                }}
                                linkValue={shareOnFacebookHelper(uid, id || '')}
                                clickCopy={copyURL}
                                closeShareOn={setShareOn}
                                copiedTooltip={copiedTooltip}
                            />
                        )}
                    </>
                )}

                {acceptReward && (
                    <div className="boxbtn-section mg-top-boxbtn">
                        <Boxbtn
                            icon={arrowAccept}
                            name="Accept Reward"
                            onClick={acceptRewardFunc}
                        />

                        <BoxbtnTransparent
                            icon={closeIconGreen}
                            name="Decline Reward"
                            onClick={() => {
                                setPopUp(true);
                            }}
                        />
                    </div>
                )}

                {popUp ? (
                    <PopupQuestion
                        onClickConfirm={() => {
                            declineReward();
                        }}
                        onClickCancel={() => {
                            setPopUp(false);
                        }}
                        headingText={'Are you sure you want to remove this reward ?'}
                        bodyMessage={"You won't be able to revert this action"}
                        buttonTxtCancel={'NO'}
                        buttonTxtConfirm={'YES'}
                        className="position-absolute"
                    />
                ) : null}
            </div>

            <Alert
                open={alertOpen.open}
                handleClose={goToMyRewards}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    );
}
