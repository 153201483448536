import { Center, Environment, OrbitControls } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import React, { Suspense } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

export function Camera({ settings, cameraRef }) {
    const {
        camera,
        gl: { domElement }
    } = useThree();

    React.useEffect(() => {
        camera.position.set(
            settings?.cameraPositionX,
            settings?.cameraPositionY,
            settings?.cameraPositionZ
        );
        cameraRef.current = camera;
    }, [settings, camera]);

    return (
        <OrbitControls
            autoRotateSpeed={2}
            autoRotate={true}
            enableZoom={false}
            enablePan={false}
            ref={cameraRef}
            target={[0, 0, 0]}
            minPolarAngle={Math.PI / 2}
            maxPolarAngle={Math.PI / 2}
            args={[camera, domElement]}
        />
    );
}

export default function Model(props: any) {
    const cameraRef = React.useRef<any>(null);
    const modelRef = React.useRef<any>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const { width, height } = useWindowSize();

    const [object, setObject] = React.useState(null);
    const [scene, setScene]: any = React.useState(null);
    const [cameraPosition, setCameraPosition]: any = React.useState({
        cameraPositionX: 0,
        cameraPositionY: 0,
        cameraPositionZ: 5
    });

    React.useEffect(() => {
        if (props?.visualPresentation) {
            const loader = new GLTFLoader();

            loader.load(props?.visualPresentation as string, (gltf: any) => {
                setObject(gltf.scene);
            });
        }
    }, [props]);

    React.useEffect(() => {
        if (object) {
            onResize();

            window.addEventListener('resize', onResize);
        }

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [object]);

    const onResize = () => {
        if (object) {
            let bbox = new THREE.Box3().setFromObject(object);
            // let helper = new THREE.Box3Helper(bbox, new THREE.Color(0, 255, 0));
            let size = bbox.getSize(new THREE.Vector3()); // HEREyou get the size

            setScene(object);
            setCameraPosition({
                cameraPositionX: 0,
                cameraPositionY: 0,
                cameraPositionZ: depthCalculator(size.x, size.y, size.z)
            });
        }
    };

    const depthCalculator = (x, y, z) => {
        return 0.5 * x + 0.5 * y + z;
    };

    console.log(modelRef);
    return (
        <div className="threejs-model-wrap">
            <div className="no-top-border-radius ReceivedRewardPreview-wrap mg-bottom">
                <div ref={containerRef} className="elements-threejs-model">
                    <Canvas>
                        <ambientLight intensity={0.3} />

                        <Suspense fallback={null}>
                            {scene && (
                                <Center>
                                    <primitive ref={modelRef} object={scene} />
                                </Center>
                            )}

                            <Environment files="/models/hdr.hdr" />
                        </Suspense>

                        <Camera settings={cameraPosition} cameraRef={cameraRef} />
                    </Canvas>
                </div>
            </div>
            {scene && (
                <Confetti
                    recycle={false}
                    numberOfPieces={2000}
                    tweenDuration={15000}
                    width={width}
                    height={height}
                />
            )}
        </div>
    );
}

// useGLTF.preload('/models/Coin.glb');
