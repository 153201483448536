import React from 'react';
import { canvasToImage } from '../../utils';
import '../../Style/Canvas.scss';

export default function Certificate(props: any) {
    const [Image, setImage] = React.useState('');

    React.useEffect(() => {
        if (props?.visualPresentation) {
            setImage(props.visualPresentation);
        }
    }, [props]);

    return (
        <div className="canvas-wrap">
            {Image ? <img src={Image} alt="Certificate" className="image" /> : 'loading...'}
        </div>
    );
}
