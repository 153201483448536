import React, { useEffect, useState } from 'react';

import '../Style/App.scss';
import '../Style/Responsive.scss';

import TopNavigationHeader from '../components/TopNavigationHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { VIEWS } from '../utils';
import jwt from 'jsonwebtoken';
import { _receivingPrivateKey } from '../redux/identitySlice';
import Spinner from '../components/Spinner';
import Alert from '../components/Alert';
import Elements from '../elements';

function AcceptReward() {
    const [isKeysGenerated, setIsKeysGenerated] = useState(true);
    const [rewards, setRewards] = useState([]);
    const history = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' });

    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(location.search).get('id');

    useEffect(() => {
        setIsDataLoading(true);
        if (id && id != 'undefined') {
            getRewards(uid, id).then((res) => {
                setRewards(res.data.credentials);
                setIsDataLoading(false);
            });
        }
    }, []);

    const getRewards = async (did: string, id: string) => {
        const rewards = await axios.get(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/getUnacceptedRewardCredentials`,
            {
                params: {
                    did,
                    id
                }
            }
        );

        return rewards;
    };

    function handleBack() {
        history(-1);
        //console.log('Clicked successfully.');
    }

    function handleDone() {
        //console.log('Clicked successfully.');
    }

    function goToMyRewards() {
        setAlertOpen({ ...alertOpen, open: false });
        history(`/${uid}${VIEWS.MYREWARDS}`);
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Received Reward"
                    handleBack={handleBack}
                    handleDone={handleDone}
                    disabled={isKeysGenerated !== true}
                />

                <Elements
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    hideDownloadButtons
                    loadingData={isDataLoading}
                    acceptReward
                    hideShare
                    reward={rewards[0] ?? {}}
                />

                <Alert
                    open={alertOpen.open}
                    handleClose={goToMyRewards}
                    handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                    title={alertOpen.title}
                    content={alertOpen.content}
                />
            </div>
            {(isLoading || isDataLoading) && <Spinner />}
        </React.Fragment>
    );
}

export default AcceptReward;
