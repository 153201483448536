import { setAddress, setLoading, setSigner } from '../../redux/metaMaskSignerSlice';
import Web3Modal from 'web3modal';
import { Web3Provider } from '@ethersproject/providers';

export const connectWallet = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const web3Modal = new Web3Modal({ cacheProvider: true });
        const instance = await web3Modal.connect();
        const provider = new Web3Provider(instance);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        dispatch(setSigner(signer));
        dispatch(setAddress(address));
    } catch (error) {
        console.log(error);
    }
    dispatch(setLoading(false));
};
