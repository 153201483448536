import React, { Component } from 'react';

export default function PopupQuestion(props: {
    onClickConfirm: any;
    onClickCancel: any;
    headingText: any;
    bodyMessage: any;
    buttonTxtConfirm: any;
    buttonTxtCancel: any;
    className?: string;
}) {
    return (
        <React.Fragment>
            <div className={`popupOverLay ${props?.className}`}>
                <div className="popup-wrap">
                    <div className="popup-heading">
                        <p>{props.headingText}</p>
                    </div>
                    <p className="popup-body">{props.bodyMessage}</p>
                    <button className="popup-btn" onClick={props.onClickConfirm}>
                        {props.buttonTxtConfirm}
                    </button>
                    <button className="popup-btn" onClick={props.onClickCancel}>
                        {props.buttonTxtCancel}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}
