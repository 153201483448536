import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface MetaMaskSignerState {
    signer: any;
    address: string;
    loading: boolean;
}

const initialState: MetaMaskSignerState = {
    signer: null,
    address: '',
    loading: false,
};

export const metaMaskSignerSlice = createSlice({
    name: 'metaMaskSigner',
    initialState,
    reducers: {
        setSigner: (state, action: PayloadAction<any>) => {
            state.signer = action.payload;
        },
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    }
});

export const { setSigner, setAddress, setLoading } = metaMaskSignerSlice.actions;

export const _signer = (state: RootState) => state.metaMaskSigner.signer;
export const _address = (state: RootState) => state.metaMaskSigner.address;
export const _loading = (state: RootState) => state.metaMaskSigner.loading;

export default metaMaskSignerSlice.reducer;
