import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';
import { AlertIcons } from '../local_libs/sd-vc-lib/dist/types/utils.type';
import warningIcon from '../Assets/NFT-icons/Error.svg';
import successIcon from '../Assets/NFT-icons/Check.svg';

export const ALERT_ICONS: AlertIcons = {
    warning: 'warning',
    success: 'success'
};

export default function Alert(props: {
    id?: string;
    title: string;
    content: string;
    open: boolean;
    icon?: string;
    handleOpen: any;
    handleClose: any;
}) {
    if (props.icon) {
        var icon = null;
        switch (props.icon) {
            case ALERT_ICONS.warning:
                icon = <img src={warningIcon} alt="warning" />;
                break;
            case ALERT_ICONS.success:
                icon = <img src={successIcon} alt="success" />;
                break;
            default:
                break;
        }
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby={`${props.id}-alert-dialog-title`}
            aria-describedby={`${props.id}-alert-dialog-description`}>
            <div className="popup-wrap">
                <div className="popup-heading">
                    <p id={`${props.id}-alert-dialog-title`}>{props.title}</p>
                </div>
                {props.icon && <div className="popup-icon">{icon}</div>}
                <p className="popup-body" id={`${props.id}-alert-dialog-description`}>
                    {props.content}
                </p>
                <button className="popup-btn" onClick={props.handleClose} autoFocus>
                    Ok
                </button>
            </div>
        </Dialog>
    );
}
