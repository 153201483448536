import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import loadable from '@loadable/component';
import { useAppSelector } from './app/hooks';
import { VIEWS } from './utils';
import { _isIdentityCreated } from './redux/identitySlice';
import { _vc } from './redux/signerSlice';
import { _vp } from './redux/presenterSlice';
import { _isVerified } from './redux/verifierSlice';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './Style/App.scss';
import './Style/Responsive.scss';

import Onbording from './Views/1.0 Onbording';
import Signin from './Views/1.1 Signin';
import Signup from './Views/1.2 Signup';
import Home from './Views/2.0 Home';
import NotFound from './Views/NotFound';
import BottomNavigation from './components/BottomNavigation';
import ReceivedRewardPreview from './Views/2.5.1 ReceivedRewardPreview';
import MyRewardPreview from './Views/2.6.1 MyRewardPreview';
import ReceivedRewardPreviewTrophyCase from './Views/2.7.1 ReceivedRewardPreviewTrophyCase';
import MyRewardCredentialPreview from './Views/3.1 MyRewardCredentialPreview';
import MyRewardPresentationPreview from './Views/3.3 MyRewardPresentationPreview';

(window as any).Buffer = (window as any).Buffer || require('buffer').Buffer;

const MyProfile = React.lazy(() => import('./Views/2.1 MyProfile'));
const MyContacts = React.lazy(() => import('./Views/2.2 MyContacts'));
const ContactsProfile = React.lazy(() => import('./Views/2.2.1 ContactsProfile'));
const GlobalContactProfile = React.lazy(() => import('./Views/2.2.3 GlobalContactProfile'));
const ReceivedRequests = React.lazy(() => import('./Views/2.4 ReceivedRequests'));
const PrepareReward = React.lazy(() => import('./Views/2.4.1 PrepareReward'));
const ReceivedRewards = React.lazy(() => import('./Views/2.5 ReceivedRewards'));

const RequestReward = React.lazy(() => import('./Views/2.5.2 RequestReward'));
const MyRewards = React.lazy(() => import('./Views/2.6 MyRewards'));
const MyRewardsWithoutNavbar = React.lazy(() => import('./Views/2.6.0 MyRewards'));
const IssuedRewardPreview = React.lazy(() => import('./Views/2.6.3 IssuedRewardPreview'));
const TrophyCase = React.lazy(() => import('./Views/2.7 TrophyCase'));

const ReceivedRewardPreviewTrophyCasePublic = React.lazy(
    () => import('./Views/2.7.3 ReceivedRewardPreviewPublicTrophyCase')
);
const TrophyCasePublicView = React.lazy(() => import('./Views/2.7.2 TrophyCasePublicView'));
const Verifier = React.lazy(() => import('./Views/2.8.1 VerifiedReward'));
const InviteCollegues = React.lazy(() => import('./Views/2.9 InviteCollegues'));
const SignedX = React.lazy(() => import('./Views/SignedX'));
const ViewMyId = React.lazy(() => import('./Views/2.1.1 ViewMyId'));
const Presenter = React.lazy(() => import('./Views/Presenter'));
const SeeMore = React.lazy(() => import('./Views/2.8.3 VerificationDetailsVC'));
const VerifyDomain = React.lazy(() => import('./Views/5.0 ClaimIDByDomain'));
const VerifyReward = React.lazy(() => import('./Views/VerifyReward'));
const GeneratedRewardPreview = React.lazy(() => import('./Views/2.4.1.2 GeneratedRewardPreview'));
const Notifications = React.lazy(() => import('./Views/2.3 Notifications'));
const BadgeTemplates = React.lazy(() => import('./Views/2.4.1.1 BadgeTemplates'));
const RewardCertificateView = React.lazy(() => import('./Views/RewardCertificateView'));
const MyRewardCredentials = React.lazy(() => import('./Views/3.0 MyRewardCredentials'));
const NewReward = React.lazy(() => import('./Views/3.2 NewReward'));
const LinkedInCallback = React.lazy(() => import('./Views/Callbacks/LinkedIn'));
const DidSiopCallback = React.lazy(() => import('./Views/Callbacks/DidSiop'));

export default function App() {
    const isIdentityCreated = useAppSelector(_isIdentityCreated);

    return (
        <Suspense fallback={null}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY as string}>
                {isIdentityCreated ? (
                    <React.Fragment>
                        <div className="containers-wrap">
                            <Routes>
                                <Route
                                    path={VIEWS.ONBORDING}
                                    element={<Navigate to={VIEWS.HOME} />}
                                />

                                <Route path={VIEWS.HOME} element={<Home />} />

                                <Route path={VIEWS.PROFILE} element={<MyProfile />} />

                                <Route path={VIEWS.INVITE} element={<InviteCollegues />} />

                                <Route path={VIEWS.MYCONTACT} element={<MyContacts />} />

                                <Route path={VIEWS.CONTACTPROFILE} element={<ContactsProfile />} />

                                <Route path={VIEWS.MYREWARDPREVIEW} element={<MyRewardPreview />} />

                                <Route
                                    path={VIEWS.MYREWARDCREDENTIALS}
                                    element={<MyRewardCredentials />}
                                />

                                <Route
                                    path={VIEWS.MYREWARDPRESENTATIONPREVIEW}
                                    element={<MyRewardPresentationPreview />}
                                />

                                <Route
                                    path={VIEWS.MYREWARDPCREDENTIALREVIEW}
                                    element={<MyRewardCredentialPreview />}
                                />

                                <Route
                                    path={VIEWS.ISSUEDREWARDPREVIEW}
                                    element={<IssuedRewardPreview />}
                                />

                                <Route
                                    path={VIEWS.TROPHYVIEW}
                                    element={<ReceivedRewardPreviewTrophyCase />}
                                />

                                <Route path={VIEWS.TROPHYCASE} element={<TrophyCase />} />

                                <Route
                                    path={VIEWS.RECEIVEDREQUESTS}
                                    element={<ReceivedRequests />}
                                />

                                <Route path={VIEWS.RECEIVEDREWARDS} element={<ReceivedRewards />} />

                                <Route path={VIEWS.REQUESTREWARD} element={<RequestReward />} />

                                <Route
                                    path={VIEWS.RECEIVEDREWARDPREVIEW}
                                    element={<ReceivedRewardPreview />}
                                />

                                <Route path={VIEWS.ISSUE} element={<PrepareReward />} />

                                <Route path={VIEWS.BADGES} element={<BadgeTemplates />} />

                                <Route
                                    path={VIEWS.GENREWARDPREVIEW}
                                    element={<GeneratedRewardPreview />}
                                />

                                <Route path={VIEWS.ACCEPT} element={<Presenter />} />

                                <Route
                                    path={VIEWS.SIGNEDVC}
                                    element={<SignedX type="Credential" />}
                                />

                                <Route
                                    path={VIEWS.SIGNEDVP}
                                    element={<SignedX type="Presentation" />}
                                />

                                <Route path={`${VIEWS.IDENTITY}/:path`} element={<ViewMyId />} />

                                <Route path={VIEWS.VERIFYDOMAIN} element={<VerifyDomain />} />

                                <Route path={VIEWS.VERIFYREWARD} element={<VerifyReward />} />

                                <Route
                                    path={VIEWS.REWARDCERTIFICATE}
                                    element={<RewardCertificateView />}
                                />

                                <Route path={VIEWS.NEWREWARD} element={<NewReward />} />

                                <Route
                                    path={`${VIEWS.VERIFIER}/:did/:vpId`}
                                    element={<Verifier />}
                                />

                                <Route
                                    path={VIEWS.GLOBALCONTACTPROFILE}
                                    element={<GlobalContactProfile />}
                                />

                                <Route path={`/:did${VIEWS.MYREWARDS}`} element={<MyRewards />} />

                                <Route
                                    path={VIEWS.TROPHYCASEPUBLIC}
                                    element={<TrophyCasePublicView />}
                                />

                                <Route
                                    path={VIEWS.TROPHYVIEWPUBLIC}
                                    element={<ReceivedRewardPreviewTrophyCasePublic />}
                                />

                                <Route path={VIEWS.MORE} element={<SeeMore />} />

                                <Route path="*" element={<NotFound />} />
                            </Routes>

                            <BottomNavigation />
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="containers-wrap">
                            <Routes>
                                <Route
                                    path={VIEWS.HOME}
                                    element={<Navigate to={VIEWS.ONBORDING} />}
                                />

                                <Route
                                    path={VIEWS.ONBORDING}
                                    element={<Navigate to={VIEWS.SIGNIN} />}
                                />

                                <Route
                                    path={`${VIEWS.SIGNIN}/:org?/:invitationId?`}
                                    element={<Signin />}
                                />

                                <Route
                                    path={`${VIEWS.SIGNUP}/:org?/:invitationId?`}
                                    element={<Signup />}
                                />

                                <Route
                                    path={VIEWS.ISSUE}
                                    element={<Navigate to={VIEWS.ONBORDING} />}
                                />

                                <Route
                                    path={VIEWS.BADGES}
                                    element={<Navigate to={VIEWS.ONBORDING} />}
                                />

                                <Route
                                    path={VIEWS.ACCEPT}
                                    element={<Navigate to={VIEWS.ONBORDING} />}
                                />

                                <Route
                                    path={VIEWS.SIGNEDVC}
                                    element={<Navigate to={VIEWS.ISSUE} />}
                                />

                                <Route
                                    path={VIEWS.SIGNEDVP}
                                    element={<Navigate to={VIEWS.ACCEPT} />}
                                />

                                <Route
                                    path={`${VIEWS.VERIFIER}/:did/:vpId`}
                                    element={<Verifier />}
                                />

                                <Route
                                    path={VIEWS.GLOBALCONTACTPROFILE}
                                    element={<GlobalContactProfile />}
                                />

                                <Route
                                    path={`/:did${VIEWS.MYREWARDS}`}
                                    element={<MyRewardsWithoutNavbar />}
                                />

                                <Route
                                    path={VIEWS.TROPHYCASEPUBLIC}
                                    element={<TrophyCasePublicView />}
                                />

                                <Route
                                    path={VIEWS.TROPHYVIEWPUBLIC}
                                    element={<ReceivedRewardPreviewTrophyCasePublic />}
                                />

                                <Route path={VIEWS.MORE} element={<SeeMore />} />

                                <Route
                                    path={`${VIEWS.LINKEDIN_CALLBACK}/:type/:org?/:invitationId?`}
                                    element={<LinkedInCallback />}
                                />

                                <Route
                                    path={`${VIEWS.DIDSIOP_CALLBACK}/:type/:org?/:invitationId?`}
                                    element={<DidSiopCallback />}
                                />

                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </div>
                    </React.Fragment>
                )}
            </GoogleOAuthProvider>
        </Suspense>
    );
}
