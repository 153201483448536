import NFT_MARKET from './MicroRewards.json';
import { Contract } from 'ethers';
import { nftStorage } from '../nft-storage';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setLoading } from '../../redux/metaMaskSignerSlice';
import { BigNumber } from 'ethers';

const NFT_MARKET_ADDRESS = process.env.REACT_APP_MARKET_ADDRESS as string;

export type NFT_METADATA = {
    name: string;
    description: string;
    json: any;
};

const useNFTMarket = () => {
    const dispatch = useAppDispatch();
    const { signer } = useAppSelector((state) => state.metaMaskSigner);
    const nftMarket = new Contract(NFT_MARKET_ADDRESS, NFT_MARKET.abi, signer);

    const createNFT = async (data: NFT_METADATA, tokenId: string) => {
        dispatch(setLoading(true));
        try {
            const uri = await nftStorage(data.name, data.description, data.json);
            const tokenIdBigNumber = BigNumber.from('0x' + tokenId).toBigInt();
            const transaction = await nftMarket.safeMint(uri, tokenIdBigNumber, { value: 10 });
            await transaction.wait();
        } catch (error) {
            throw new Error(error);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const nftExists = async (tokenId: string) => {
        const tokenIdBigNumber = BigNumber.from('0x' + tokenId).toBigInt();
        const exists = await nftMarket.exists(tokenIdBigNumber);
        return exists;
    };

    return { createNFT, nftExists };
};

export default useNFTMarket;
