import React from 'react';
import Alert from '../Alert';
import { CustomDidResolver } from '../../utils/custom-did-resolver';
import { VIEWS } from '../../utils';
import * as SIOP from 'did-siop';
import SiopIco from '../../Assets/logos/siop.svg';
import ZedeidSiopIco from '../../Assets/logos/zedeid_logo.svg';

interface Props {
    type?: string;
    children?: any;
    onClick?: any;
    org?: string;
    invitationId?: string;
}

interface SIOPData {
    redirect_uri: string;
    did: string;
    private_key: string;
    registration: object;
    claims: object;
}

export default function SiopLoginButton({
    type = 'signin',
    children = 'Login With SIOP',
    org,
    invitationId
}: Props) {
    const LoginBtnRef = React.useRef<HTMLButtonElement>(null);
    const [alertOpen, setAlertOpen] = React.useState({ open: false, title: '', content: '' });

    React.useEffect(() => {
        generateRequest();
    }, [LoginBtnRef]);

    async function generateRequest() {
        const redirect_uri = `${window.location.origin}${VIEWS.DIDSIOP_CALLBACK}`;
        let url = `${redirect_uri}/${type}`;

        if (org) url.concat(`/${org}`);
        if (invitationId) url.concat(`/${invitationId}`);

        let data: SIOPData = {
            redirect_uri: url,
            did: process.env.REACT_APP_DID,
            private_key: process.env.REACT_APP_PRIVATE_KEY,
            registration: {
                jwks_uri:
                    'https://uniresolver.io/1.0/identifiers/did:example:0xab;transform-keys=jwks',
                id_token_signed_response_alg: ['ES256K', 'EdDSA', 'RS256']
            },
            claims: { id_token: { did: null, name: null, email: null } }
        };

        const resolver = new SIOP.Resolvers.CombinedDidResolver('key');
        const customResolver = new CustomDidResolver();
        resolver.removeAllResolvers();
        resolver.addResolver(customResolver);

        let siopRp = await SIOP.RP.getRP(
            data.redirect_uri, // redirect_uri
            data.did, // did
            data.registration, // registration
            undefined,
            [resolver]
        );

        siopRp.addSigningParams(data.private_key);

        let siopRequest = await siopRp.generateRequest({
            response_mode: 'tab-url',
            claims: data.claims
        });

        if (!siopRequest) {
            setAlertOpen({
                open: true,
                title: 'SIOP Login',
                content: 'SIOP login failed'
            });
            return;
        }

        if (LoginBtnRef?.current) {
            LoginBtnRef.current.dataset.didSiop = siopRequest;
        }

        /* to extension to detect the login button */
        localStorage.setItem('new-content', 'true');
    }

    async function handleSigninWithSIOP(e: React.MouseEvent<HTMLElement>) {
        const target = e?.currentTarget;

        if (target?.dataset?.active !== 'true') {
            setAlertOpen({
                open: true,
                title: 'SIOP Login',
                content: 'Please login with the SIOP extension and try again'
            });
            return;
        }

        if (target?.dataset?.didSiop && target?.dataset?.didSiop !== 'pre-generated-request') {
            setAlertOpen({
                open: true,
                title: 'SIOP Login',
                content: 'Login request sent. Please check your SIOP DID Extension'
            });
            return;
        }
    }

    return (
        <>
            <button
                className="social-content-button"
                ref={LoginBtnRef}
                onClick={handleSigninWithSIOP}
                data-did-siop="pre-generated-request"
                data-active="false">
                <img src={ZedeidSiopIco} className="icon-image s-md" />
                <span className="text">{children}</span>
            </button>

            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </>
    );
}
