import { Checkbox, FormControlLabel } from '@material-ui/core';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode.react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddToExtensionButton from '../components/AddToExtensionButton';
import Alert, { ALERT_ICONS } from '../components/Alert';
import Button from '../components/Button';
import { shareOnFacebookHelper } from '../utils';
import Badge from './element/Badge';
import Certificate from './element/Certificate';
import Model from './element/Model';
import Other from './element/Other';
import Transcript from './element/Transcript';
import useNFTMarket, { NFT_METADATA } from '../utils/nftMarket';
import { connectWallet } from '../utils/nftMarket/actions';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import createVP from '../utils/create-vp';
import { _receivingPrivateKey } from '../redux/identitySlice';
import { dateDiffInDays, dateDiffInMinutes } from '../utils/helpers';
import { setLoading } from '../redux/metaMaskSignerSlice';
import { BigNumber } from 'ethers';

const elements: any = {
    BADGE: Badge,
    CERTIFICATE: Certificate,
    TRANSCRIPT: Transcript,
    OTHER: Other,
    MODEL: Model
};

interface Props {
    index?: number;
    type?: any;
    vc?: any;
    element?: any;
    allowHide?: boolean;
    hide?: boolean;
    viewed?: boolean;
    hideDownloadButtons?: boolean;
    setHide?: any;
    mintedTimestamp?: Date | null;
    minted: boolean;
}

export default function Element({
    index = 0,
    type,
    vc = {},
    element = {},
    allowHide = false,
    hide = false,
    viewed = false,
    minted = undefined,
    hideDownloadButtons = false,
    mintedTimestamp = undefined,
    setHide
}: Props) {
    const [alertOpen, setAlertOpen] = React.useState({
        open: false,
        title: '',
        content: null,
        icon: undefined
    });

    const Render = elements[type];

    const location = useLocation();
    const userToken = sessionStorage.getItem('userToken');
    const decodedUserToken: any = jwt.decode(userToken as string);
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(location.search).get('id');
    const { createNFT, nftExists } = useNFTMarket();
    const { loading, signer } = useAppSelector((state) => state.metaMaskSigner);
    const dispatch = useAppDispatch();
    const holderPrivateKey = useAppSelector(_receivingPrivateKey);
    const now = new Date();
    function onSaveToFile() {
        if (element) {
            const pdf = new jsPDF({
                unit: 'px'
            });

            const imgProps = pdf.getImageProperties(element.visualPresentation);
            pdf.internal.pageSize.width = imgProps.width;
            pdf.internal.pageSize.height = imgProps.height;

            pdf.addImage(
                element.visualPresentation as string,
                'png',
                0,
                0,
                imgProps.width,
                imgProps.height
            );
            pdf.save(`${element.title}-${element.type[0]}.pdf`);
        }
    }

    React.useEffect(() => {
        const element_id = element?.id?.split('/')[5];
        const viewed = window.sessionStorage.getItem(`${element_id}-viewed`);

        if (viewed !== 'true') {
            window.sessionStorage.setItem(`${element_id}-viewed`, 'true');
            onUpdateViewCount();
        }
    }, [element]);

    const onUpdateViewCount = async () => {
        const element_id = element?.id?.split('/')[5];

        await axios.patch(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/updateElementViewCount/${element_id}`,
            {
                appType: 'wallet-app'
            }
        );
    };

    const onHideElement = async () => {
        const state = !hide;
        setHide(state);

        const result = await axios.patch(
            `${process.env.REACT_APP_REWARD_BACKEND}reward/updateElement/${id}`,
            {
                index: index,
                state: state
            }
        );
    };

    const getExtensionDataFormat = (json: any) => {
        return btoa(JSON.stringify(json));
    };

    const onGenerateQR = () => {
        setAlertOpen({
            open: true,
            title: 'Scan QR Code',
            icon: undefined,
            content: (
                <div className="elements-qr-code">
                    <QRCode value={shareOnFacebookHelper(uid, id)} size={200} renderAs="svg" />
                </div>
            )
        });
    };

    const onNFTCreate = async () => {
        try {
            const vc_id = vc.id.split('/').pop();
            const vp: any = await createVP({
                did: uid,
                vcs: [vc],
                private_key: holderPrivateKey
            });
            const nftMetadata: NFT_METADATA = {
                name: element.title,
                description: element.description ?? '',
                json: vp
            };
            await axios.put(
                `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/pending/${vc_id}`
            );
            await createNFT(nftMetadata, vc_id);
            await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}minted/presentation`, vp, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            await axios.delete(
                `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/confirm/${vc.id
                    .split('/')
                    .pop()}`
            );
            console.error(error);
        }
    };

    const wasAdded = async () => {
        try {
            const vc_id = vc.id.split('/').pop();
            dispatch(setLoading(true));
            await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC721',
                    options: {
                        address: process.env.REACT_APP_MARKET_ADDRESS,
                        tokenId: BigNumber.from('0x' + vc_id).toString() ?? '0'
                    }
                }
            });
        } catch (error) {
            alert(error.message);
            console.error(error.message);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const checkNFTStatus = async () => {
        try {
            dispatch(setLoading(true));
            const vc_id = vc.id.split('/').pop();
            const minted = await nftExists(vc_id);
            if (minted) {
                await axios.put(
                    `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/confirm/${vc_id}`
                );
                setAlertOpen({
                    open: true,
                    title: 'NFT Status',
                    icon: ALERT_ICONS.success,
                    content: (
                        <div className="alert-content">
                            NFT has been minted. Please refresh the page
                        </div>
                    )
                });
                if (!mintedTimestamp) {
                    const res = await axios.put(
                        `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/updatetime/${vc_id}`
                    );
                }
            } else {
                const res = await axios.delete(
                    `${process.env.REACT_APP_REWARD_BACKEND}minted/presentation/confirm/${vc_id}`
                );
                setAlertOpen({
                    open: true,
                    title: 'NFT Status',
                    icon: ALERT_ICONS.warning,
                    content: (
                        <div className="alert-content">
                            Looks like minting of your NFT failed. Please refresh the page and Press
                            "Generate NFT" to start the process again. This will cost you money
                        </div>
                    )
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(setLoading(false));
        }
    };

    if (!Render) return null;

    return (
        <div className="insideComponentsWrap">
            <Render {...element} viewed={viewed} />

            {!hideDownloadButtons && (
                <>
                    {type !== 'MODEL' && (
                        <div className="extraMargin-iR-Btn">
                            <Button onClick={onSaveToFile} name="Save to a File" />
                        </div>
                    )}

                    <div className="extraMargin-iR-Btn">
                        <AddToExtensionButton
                            data={getExtensionDataFormat(vc)}
                            className="btn-green"
                        />
                    </div>

                    <div className="extraMargin-iR-Btn">
                        <Button onClick={onGenerateQR} name="Generate QR Code" />
                    </div>
                </>
            )}

            {window.ethereum?.isMetaMask ? (
                minted == undefined ? (
                    <div className="view-nft">
                        <Button
                            onClick={
                                signer != null
                                    ? onNFTCreate
                                    : async () => await connectWallet(dispatch)
                            }
                            disabled={loading}
                            name={
                                loading
                                    ? 'Loading...'
                                    : signer != null
                                    ? 'Generate an NFT'
                                    : 'Connect Wallet to mint NFT'
                            }
                        />
                    </div>
                ) : !minted ? (
                    <div className="view-nft">
                        <Button
                            onClick={checkNFTStatus}
                            disabled={
                                loading || dateDiffInMinutes(now, new Date(mintedTimestamp)) < 1
                            }
                            name={loading ? 'Loading..' : 'Check NFT Status'}
                        />
                        <span className="note">
                            {dateDiffInMinutes(now, new Date(mintedTimestamp)) < 1 &&
                                '( You can check the status of your NFT within 1 minute of the minted time )'}
                        </span>
                    </div>
                ) : (
                    <div className="view-nft">
                        <Button
                            onClick={wasAdded}
                            disabled={loading}
                            name={loading ? 'Loading...' : 'View NFT'}
                        />
                        <span className="note">
                            {dateDiffInDays(now, new Date(mintedTimestamp)) <= 1 &&
                                '( You can view your NFT within 48 hours of the minted time )'}
                        </span>
                    </div>
                )
            ) : (
                <div className="view-nft">
                    <Button
                        onClick={() => (window.location.href = 'https://metamask.io/download/')}
                        name={'Install Metamask to view/mint NFTs'}
                    />
                </div>
            )}

            {allowHide && (
                <div className="elements-hide-checkbox checkbox-wrap">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hide}
                                className="greenCheckBox"
                                onChange={() => onHideElement()}
                            />
                        }
                        label={<span className="greenTxt">Hide this reward</span>}
                    />
                    <span className="note">
                        ( Conceal the reward element to prevent its display within the viewing
                        application. )
                    </span>
                </div>
            )}

            <div className="extra-mt2"></div>

            {element?.attributesValues?.length > 0 && (
                <div className="ReceivedRewardPreview-wrap">
                    <div className="issuerDetails">
                        <p className="sectionTitle">
                            <span>Additional Details</span>
                        </p>
                        {element?.attributesValues?.map((item: any, index: number) => (
                            <div className="addSectionAttributePreview" key={index.toString()}>
                                <div className="sectionAttributePreview">{item?.name}</div>
                                <div className="sectionValue">{item?.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                icon={alertOpen.icon}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </div>
    );
}
