import { ChangeEvent, Fragment, useState } from 'react';

import { useAppDispatch } from '../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import Spinner from '../components/Spinner';
import { VIEWS } from '../utils';
import axios from 'axios';
import Wallet, { Types, generateMnemonic } from 'did-hd-wallet';
import VCSD from '../local_libs/sd-vc-lib/dist';
import jwt from 'jsonwebtoken';
import { createHash } from 'crypto';
import {
    setHolderDID,
    setHolderPrivateKey,
    setImage,
    setIsIdentityCreated,
    setIssuerDID,
    setIssuerPrivateKey,
    setMnemonic,
    setName,
    setEmail,
    setEnrolledActivity
} from '../redux/identitySlice';
import Alert from '../components/Alert';
import TopNavigationHeader from '../components/TopNavigationHeader';
import ProfilePictureDefault from '../Assets/ProfilePictureDefault';

import Logo from '../components/Logo';
import Button from '../components/Button';
import LinkedinIco from '../Assets/linkedin.svg';
import SiopLoginButton from '../components/logins/SiopLoginButton';

import '../Style/Signup.scss';
import GoogleLoginButton from '../components/logins/GoogleLoginButton';
import FacebookLoginButton from '../components/logins/FacebookLoginButton';
import TwitterLoginButton from '../components/logins/TwitterLoginButton';
import LinkedInLoginButton from '../components/logins/LinkedInLoginButton';

export default function Signup() {
    const dispatch = useAppDispatch();
    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' });
    const userLogged = 'hkfsuhfry77yr43riuhf34fy7fheiwowjf89r4';
    const { org, invitationId } = useParams<any>();

    function handleSignupWithLinkedin() {
        const redirect_uri = `${window.location.origin}${VIEWS.LINKEDIN_CALLBACK}`;
        let url = `${redirect_uri}/signup`;

        if (org) url.concat(`/${org}`);
        if (invitationId) url.concat(`/${invitationId}`);

        window.open(
            `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${url}&scope=r_liteprofile`,
            '_blank'
        );
    }

    async function handleSignupWithMnemonic() {
        try {
            const mnemonic = generateMnemonic(128);
            const ed = new VCSD.utils.ed25519();

            const wallet = new Wallet(Types.MNEMONIC, mnemonic);

            const uid: string = createHash('sha256').update(mnemonic).digest('hex');
            const {
                publicKey: issuerPublicKey,
                privateKey: issuerPrivateKey,
                did: issuerDID,
                verificationKey: issuerVerificationKey
            }: any = await wallet.getChildKeys(
                process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1'
            );
            const {
                publicKey: holderPublicKey,
                privateKey: holderPrivateKey,
                did: holderDID,
                verificationKey: holderVerificationKey
            }: any = await wallet.getChildKeys(
                process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2'
            );

            try {
                setIsLoading(true);
                const issuerChallengeResponse = await axios.post(
                    `${process.env.REACT_APP_RESOLVER}`,
                    { did: issuerDID }
                );
                const { challenge: issuerChallenge } = jwt.decode(
                    issuerChallengeResponse.data.challengeToken
                ) as any;

                const issuerResponse = await axios.post(`${process.env.REACT_APP_RESOLVER}`, {
                    did: issuerDID,
                    verificationKey: issuerVerificationKey,
                    challengeResponse: {
                        publicKey: issuerPublicKey,
                        cipherText: ed
                            .sign(
                                Buffer.from(issuerChallenge, 'hex'),
                                Buffer.from(issuerPrivateKey as string, 'hex')
                            )
                            .toHex(),
                        jwt: issuerChallengeResponse.data.challengeToken
                    }
                });

                if (issuerResponse?.data?.status !== 'success') {
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: 'DID document creation failed'
                    });

                    setIsLoading(false);

                    return false;
                }

                const holderChallengeResponse = await axios.post(
                    `${process.env.REACT_APP_RESOLVER}`,
                    { did: holderDID }
                );

                const { challenge: holderChallenge } = jwt.decode(
                    holderChallengeResponse.data.challengeToken
                ) as any;

                const holderResponse = await axios.post(`${process.env.REACT_APP_RESOLVER}`, {
                    did: holderDID,
                    verificationKey: holderVerificationKey,
                    challengeResponse: {
                        publicKey: holderPublicKey,
                        cipherText: ed
                            .sign(
                                Buffer.from(holderChallenge, 'hex'),
                                Buffer.from(holderPrivateKey as string, 'hex')
                            )
                            .toHex(),
                        jwt: holderChallengeResponse.data.challengeToken
                    }
                });

                const userTokenResponse = await axios.post(
                    `${process.env.REACT_APP_REWARD_BACKEND}user/signup/mnemonic`,
                    {
                        uid,
                        name: userName,
                        email: userEmail,
                        receivingDID: holderDID,
                        photo: ProfilePictureDefault,
                        org,
                        invitationId
                    }
                );

                if (issuerResponse.data && holderResponse.data) {
                    if (!userTokenResponse.data) {
                        return setAlertOpen({
                            open: true,
                            title: 'Error',
                            content: 'Unknown error. Try again'
                        });
                    }

                    const userToken = userTokenResponse.data.token;
                    const activityData = userTokenResponse.data.data;
                    sessionStorage.setItem('userToken', userToken);
                    const decodedUser: any = jwt.decode(userToken);

                    dispatch(setMnemonic(mnemonic));
                    dispatch(setHolderDID(holderDID));
                    dispatch(setHolderPrivateKey(holderPrivateKey as string));
                    dispatch(setIssuerDID(issuerDID));
                    dispatch(setIssuerPrivateKey(issuerPrivateKey as string));
                    dispatch(setIsIdentityCreated(true));
                    dispatch(setName(userName));
                    dispatch(setEmail(userEmail));
                    dispatch(setImage(decodedUser?.photo));
                    if (activityData?.activity)
                        dispatch(setEnrolledActivity(activityData?.activity));
                    history(`${VIEWS.IDENTITY}/${userLogged}`);
                } else {
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: 'DID documents not found'
                    });
                }

                setIsLoading(false);
            } catch (err: any) {
                console.log(err);
                setIsLoading(false);
                if (err?.response?.data) {
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: err?.response?.data?.message || err?.response?.data?.error
                    });
                } else {
                    setAlertOpen({
                        open: true,
                        title: 'Error',
                        content: "Couldn't resolve DID Doc. Try again"
                    });
                }
            } finally {
                setIsLoading(false);
            }
        } catch (error: any) {
            console.log(error);
            setAlertOpen({ open: true, title: 'Error', content: error?.response?.data?.message });
        }
    }

    function handleSetName(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value;
        setUserName(name);
    }

    function handleSetEmail(event: ChangeEvent<HTMLInputElement>) {
        const email = event.target.value;
        setUserEmail(email);
    }

    const [isKeysGenerated, setIsKeysGenerated] = useState(true);
    const [isInput, setisInput] = useState(true);

    function handleBack() {
        history(VIEWS.ONBORDING);
    }

    function goToSignIn() {
        let url = VIEWS.SIGNIN;

        if (org) url.concat(`/${org}`);
        if (invitationId) url.concat(`/${invitationId}`);

        history(url);
    }

    return (
        <Fragment>
            <div className="signup-container container container-back">
                <TopNavigationHeader
                    caption=""
                    handleBack={handleBack}
                    disabled={isKeysGenerated !== true}
                />
                <Logo />
                <div className="wrap">
                    <p className="onboard-title">Create Your Account</p>

                    <GoogleLoginButton type="signup" org={org} invitationId={invitationId}>
                        Sign up With Google
                    </GoogleLoginButton>

                    <SiopLoginButton type="signup" org={org} invitationId={invitationId}>
                        Sign up With SIOP
                    </SiopLoginButton>

                    <div className="content-section">
                        <p className="onboarding-subtitle">
                            <span>Sign Up with ID Secret</span>
                        </p>

                        <form className="form-style" action="" onSubmit={(e) => e.preventDefault()}>
                            <div className="inputs">
                                <input
                                    type="email"
                                    onChange={handleSetEmail}
                                    className="normal-input margin-bottom"
                                    placeholder="Enter your email"
                                />
                                <input
                                    type="text"
                                    onChange={handleSetName}
                                    className="normal-input"
                                    placeholder="Enter your name"
                                    maxLength={30}
                                />
                            </div>
                            {/* <p className="error-validation-txt">{validationTxt}</p> */}
                            <Button
                                onClick={handleSignupWithMnemonic}
                                name="Sign Up"
                                disabled={isInput !== true}
                            />
                        </form>
                    </div>

                    <a onClick={goToSignIn} className="signInLink">
                        <p className="subtitle subtitle-mg-signup">
                            Already have an account ? <span>Sign In</span>
                        </p>
                    </a>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </Fragment>
    );
}
