import React from 'react';
import Alert from './Alert';

interface Props {
    data: any;
    className?: string;
}

export default function AddToExtensionButton({ data, className }: Props) {
    const addToExtensionBtnRef = React.useRef<HTMLButtonElement>(null);
    const [alertOpen, setAlertOpen] = React.useState({ open: false, title: '', content: '' });

    React.useEffect(() => {
        window.localStorage.setItem('new-content', 'true');
    }, []);

    const onAddToExtension = () => {
        if (addToExtensionBtnRef?.current) {
            if (addToExtensionBtnRef.current?.dataset?.active === 'false') {
                return setAlertOpen({
                    open: true,
                    title: 'Alert',
                    content:
                        'Please make sure you have installed the DID SIOP Extension in your browser.'
                });
            }

            addToExtensionBtnRef.current.innerHTML = 'Added To Extension';
            addToExtensionBtnRef.current.disabled = true;

            setTimeout(() => {
                if (addToExtensionBtnRef?.current) {
                    addToExtensionBtnRef.current.disabled = false;
                    addToExtensionBtnRef.current.innerHTML = 'Add to Extension';
                }
            }, 1000);
        }
    };

    return (
        <>
            <button
                data-active="false"
                ref={addToExtensionBtnRef}
                onClick={onAddToExtension}
                data-did-siop-vc={data}
                className={`${className} extension-btn`}>
                Add To Extension
            </button>
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </>
    );
}
