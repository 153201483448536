import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import '../Style/NotFound.scss';

export default function NotFound() {
    return (
        <div className="not-found">
            <span className="text">404</span>
            <span className="sub-text">
                The page you are looking for may have been moved, deleted, or possibly never
                existed.
            </span>
            <Link to="/">
                <Button name="Back to Home" className="back-btn" />
            </Link>
        </div>
    );
}
